import $ from 'jquery'
import global from './global'
import selects from './selects'

function init() {
    if (!$('body').hasClass('rate_manager')) {
        return
    }

    setUpEventListeners()
    checkRateData()
    checkCarrierAndQuarter()
}

function setUpEventListeners() {
    $('body.index #carrier').on('change', checkCarrierAndQuarter)
    $('body.index #quarter').on('change', checkCarrierAndQuarter)
    $('#list_container__results').on('click', '.item .actions .duplicate-button', events.onItemDuplicateButtonClick)
    $('#rate_data__container').on('cocoon:after-insert', events.onRateDataContainerCocoonAfterInsert)
    $('#rate_data__container').on('cocoon:before-remove', events.onRateDataContainerCocoonBeforeRemove)
    $('#rate_data').on('animationend', '.rate-datum', events.onRateDatumAnimationEnd)
}

function checkCarrierAndQuarter() {
    const carrier_id = $('#carrier').val(),
        quarter = $('#quarter').val(),
        category_slug = $('#container').data('category'),
        container = $('#list_container__results'),
        loader = $('#list_container__loader')

    loader.removeClass('hide')
    container.html('')

    $.getJSON(
        '/rate_manager/get_rates/' + category_slug + '.html',
        {
            carrier_id: carrier_id,
            quarter: quarter
        },
        (response) => {
            setTimeout(() => {
                if (response.success) {
                    container.html( response.html )
                }
    
                loader.addClass('hide')
            }, global.LOAD_DELAY_TIME)
        }
    )
}

function checkRateData() {
    const rate_data = $('#rate_data'),
        num_datum = rate_data.find('.rate-datum').length

    $('#rate_data').toggleClass('no-delete', num_datum == 1)
}

const events = {
    onItemDuplicateButtonClick: (e) => {
        const a = $(e.currentTarget),
            href = a.attr('href'),
            item = a.closest('.item')

        e.preventDefault()
        if (href === undefined) {
            return
        }

        item.addClass('loading')

        $.getJSON(href, (response) => {
            setTimeout(() => {
                if (response.success) {
                    $(response.html).insertAfter(item)
                }

                item.removeClass('loading')
            }, global.LOAD_DELAY_TIME)
        })
    },
    onRateDataContainerCocoonAfterInsert: () => {
        selects.init()
        checkRateData()
    },
    onRateDataContainerCocoonBeforeRemove: (e, element) => {
        element.addClass('remove')
    },
    onRateDatumAnimationEnd: (e) => {
        const datum = $(e.target)

        if (e.originalEvent.animationName == 'hide-move-down') {
            datum.addClass('removed')
        }
    }
}

export default { init }