import $ from 'jquery'
import 'select2'

function init() {
    setTimeout( setUpSelects, 50 )
}

function setUpSelects() {
    $('select').each((index, select) => {
        select = $(select)
        
        const parent = select.closest('.select-container'),
            option_widths = parent.find('.option-widths'),
            option_width = option_widths.outerWidth()

        select.select2({
            width: 12 + option_width + 8 + 20,
            placeholder: 'SELECT ONE',
            minimumResultsForSearch: Infinity,
            dropdownParent: parent
        })
    })
}

export default { init }