import $ from 'jquery'
import global from './global'
import nav from './nav'
import selects from './selects';
import rate_manager from './rate_manager';
import froala from './froala';
import modals from './modals';
import broker_calculator from './broker_calculator';
import plan_builder from './plan_builder';

const modules = [
    global,
    nav,
    selects,
    rate_manager,
    froala,
    modals,
    broker_calculator,
    plan_builder
]

$(() => {
    $(modules).each((index, module) => {
        module.init()
    })
})