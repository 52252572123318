import $ from 'jquery'

function init() {
    setUpEventListeners();
}

function setUpEventListeners() {
    $('body.broker_calculator.entries #list_container .expand-button').on('click', events.onExpandButtonClick);
}

const events = {
    onExpandButtonClick() {
        const btn = $(this),
            row = btn.closest('.row');

        row.toggleClass('expanded');
    }
}

export default { init }