import $ from 'jquery'
import froala from './froala'
import global from './global'
import Uploader from './uploader.class'

const RATE_POLL_DELAY = 500
let rate_poll_timeout = null,
    prev_serialized_form_data = null

function init() {
    if (!$('body').hasClass('plan_builder')) {
        return
    }

    setUpEventListeners()
    checkCarrier()
    setUpFormChangePoller()
}

function setUpEventListeners() {
    $('body.index #carrier').on('change', checkCarrier)
    $('#list_container__results').on('click', '.item .actions .duplicate-button', events.onItemDuplicateButtonClick)
    $('#content_blocks__container').on('cocoon:after-insert', events.onContentBlocksContainerCocoonAfterInsert)
    $('#content_blocks__container').on('cocoon:before-remove', events.onContentBlocksContainerCocoonBeforeRemove)
    $('#content_blocks__container').on('animationend', '.content-block', events.onContentBlockAnimationEnd)
    $('#content_blocks__container').on('keydown, keyup', '.content-block .autocomplete--field', events.onAutoCompleteFieldKeyDown)
    $('#content_blocks__container').on('focus', '.content-block .autocomplete--field', events.onAutoCompleteFieldFocus)
    $('#content_blocks__container').on('blur', '.content-block .autocomplete--field', events.onAutoCompleteFieldBlur)
    $('#content_blocks__container').on('click', '.content-block .autocomplete--dropdown ul li a', events.onAutoCompleteDropdownItemClick)
    $('#plan_carrier_id').on('change', events.onPlanCarrierIdChange)
    $('#plan_appended_pdf').on('change', events.onPlanAppendedPDFChange)
    $('.file .uploaded-file .remove').on('click', events.onUploadedFileRemoveButtonClick)
    $('#preview_button').on('click', events.onPreviewButtonClick)
}

function checkCarrier() {
    const carrier_id = $('#carrier').val(),
        category_slug = $('#container').data('category'),
        container = $('#list_container__results'),
        loader = $('#list_container__loader')

    loader.removeClass('hide')
    container.html('')

    $.getJSON(
        '/plan_builder/get_plans/' + category_slug,
        {
            carrier_id: carrier_id
        },
        (response) => {
            setTimeout(() => {
                if (response.success) {
                    container.html( response.html )
                }
    
                loader.addClass('hide')
            }, global.LOAD_DELAY_TIME)
        }
    )
}

function pollRates(autocomplete) {
    const input = autocomplete.find('.autocomplete--field'),
        dropdown = autocomplete.find('.autocomplete--dropdown'),
        ul = dropdown.find('ul'),
        loader = $( $('#content_blocks__container').data('li-loader-template') ),
        category = $('#container').data('category'),
        carrier_id = $('#plan_carrier_id').val()    

    clearTimeout(rate_poll_timeout)

    if (!autocomplete.hasClass('loading')) {
        ul.html(loader)
    }

    autocomplete.addClass('loading')
    updateDropdownHeightForAutocomplete(autocomplete)

    rate_poll_timeout = setTimeout(() => {
        
        $.getJSON(
            '/rate_manager/get_rates/' + category + '.json',
            {
                carrier_id: carrier_id,
                search: input.val()
            },
            (response) => {
                if (response.success) {
                    ul.append(( () => {
                        const unique_rates = []
                        let html = ''
                        
                        $(response.data).each((index, rate) => {
                            if (unique_rates.indexOf(rate.name) !== -1) {
                                return
                            }
                        
                            html += $('<li>').
                                html(
                                    $('<a>').
                                        html(rate.name).
                                        attr('data-rate-id', rate.id).
                                        data('rate-id', rate.id)
                                ).
                                prop('outerHTML')

                            unique_rates.push( rate.name )
                        })

                        return html
                    })() )
                } else {
                    ul.append(
                        $('<li>').
                            addClass('empty').
                            html(( () => {
                            if (response.message) {
                                return response.message
                            } else {
                                return 'There are no Rates to show'
                            }
                        })() )
                    )
                }

                autocomplete.
                    removeClass('loading').
                    addClass('loaded')
                updateDropdownHeightForAutocomplete(autocomplete)
            }
        )

    }, RATE_POLL_DELAY)
}

function updateDropdownHeightForAutocomplete(autocomplete) {
    const dropdown = autocomplete.find('.autocomplete--dropdown'),
        dropdown_inner_height = dropdown.find('ul').outerHeight()

    if (autocomplete.hasClass('active')) {
        dropdown.css('height', dropdown_inner_height)
    } else {
        dropdown.css('height', 0)
    }
}

function setUpFormChangePoller() {
    if (!$('form').length) {
        return
    }

    setInterval(formChangePoll, 2000)
}

function formChangePoll() {
    const main_form = $('#main_plan_form'),
        main_form_data = main_form.serialize()

    if (main_form_data === prev_serialized_form_data) {
        return
    }

    prev_serialized_form_data = main_form_data

    $.ajax({
        url: '/plan_builder/store_preview_data',
        method: 'POST',
        dataType: 'JSON',
        data: main_form_data,
        success(response) {
            if (response.success) {
                
            } else {
                console.error(response.message)
            }
        }
    })
}

const events = {
    onItemDuplicateButtonClick(e) {
        const a = $(e.currentTarget),
            href = a.attr('href'),
            item = a.closest('.item')

        e.preventDefault()
        if (href === undefined) {
            return
        }

        item.addClass('loading')

        $.getJSON(href, (response) => {
            setTimeout(() => {
                if (response.success) {
                    $(response.html).insertAfter(item)
                }

                item.removeClass('loading')
            }, global.LOAD_DELAY_TIME)
        })
    },
    onContentBlocksContainerCocoonAfterInsert() {
        froala.init();
    },
    onContentBlocksContainerCocoonBeforeRemove(e, element) {
        element.addClass('remove')
    },
    onContentBlockAnimationEnd(e) {
        const content_block = $(e.target)

        if (e.originalEvent.animationName == 'hide-move-down') {
            content_block.addClass('removed')
        }
    },
    onAutoCompleteFieldKeyDown(e, extra = undefined) {
        const input = $( e ? e.target : extra ),
            val = $.trim( input.val() ),
            autocomplete = input.closest('.autocomplete'),
            is_active = val.length > 0

        autocomplete.toggleClass('active', is_active)

        updateDropdownHeightForAutocomplete(autocomplete)

        if (is_active) {
            pollRates(autocomplete)
        }
    },
    onAutoCompleteFieldFocus() {
        const field = $(this),
            term = $.trim( field.val() ),
            autocomplete = field.closest('.autocomplete')

        if (term.length && autocomplete.hasClass('loaded')) {
            autocomplete.addClass('active')
        }

        updateDropdownHeightForAutocomplete(autocomplete)
    },
    onAutoCompleteFieldBlur(e) {
        const field = $(this),
            autocomplete = field.closest('.autocomplete')

        setTimeout(() => {
            const autocomplete_parent = $(document.activeElement).closest('.autocomplete')

            if (autocomplete_parent.length == 0) {
                autocomplete.removeClass('active')
                updateDropdownHeightForAutocomplete(autocomplete)
            }
        }, 50)
    },
    onAutoCompleteDropdownItemClick(e) {
        const item = $(this),
            rate_id = item.data('rate-id'),
            autocomplete = item.closest('.autocomplete'),
            input_field = autocomplete.find('.autocomplete--field'),
            search_panel = autocomplete.closest('.rate_search_panel'),
            hidden_field = search_panel.find('.rate-id-value')

        e.preventDefault()

        autocomplete.removeClass('active')
        input_field.val( item.text() )
        hidden_field.val( rate_id )
        updateDropdownHeightForAutocomplete(autocomplete)
    },
    onPlanCarrierIdChange() {
        $('.autocomplete').each((index, autocomplete) => {
            autocomplete = $(autocomplete)

            const field = autocomplete.find('.autocomplete--field'),
                search_panel = autocomplete.closest('.rate_search_panel'),
                hidden_field = search_panel.find('.rate-id-value')

            field.val('')
            autocomplete.removeClass('active')
            hidden_field.val('')
        })
    },
    onPlanAppendedPDFChange() {
        const field = $(this),
            upload_url = field.data('direct-upload-url'),
            file_block = field.closest('.file'),
            files = field.get(0).files

        Array.from(files).forEach(file => {
            const uploader = new Uploader(file, upload_url, file_block)

            uploader.upload()
        })
    },
    onUploadedFileRemoveButtonClick() {
        const file_block = $(this).closest('.file'),
            hidden_field = file_block.find('.file-value-field')

        hidden_field.val('')
        file_block.removeClass('uploaded')
    },
    onPreviewButtonClick(e) {
        
    },
    onFormElementChange() {
        console.log('form change')
    }
}

export default { init }