import $ from 'jquery'
import { DirectUpload } from '@rails/activestorage'

export default class Uploader {

    constructor(file, url, file_block) {
        this.direct_upload = new DirectUpload(file, url, this)
        this.$file_block = file_block
    }

    upload() {
        const hidden_field = this.$file_block.find('.file-value-field'),
            upload_field = this.$file_block.find('.field input'),
            uploaded_file_text = this.$file_block.find('.uploaded-file .text'),
            progress_bar = this.$file_block.find('.progress--bar'),
            progress_text = this.$file_block.find('.progress--status .text'),
            progress_percent = this.$file_block.find('.progress--status .percent')

        progress_bar.css('width', 0)
        progress_text.html('STARTING UPLOAD&hellip;')
        progress_percent.html('')
        this.$file_block.addClass('uploading')

        this.direct_upload.create((error, blob) => {
            if (error) {
                progress_bar.css('width', 0)
                progress_text.html('AN ERROR OCCURRED. PLEASE TRY AGAIN LATER.')
                progress_percent.html('')

                setTimeout(() => {
                    this.$file_block.removeClass('uploading')
                }, 2000)
            } else {
                hidden_field.val( blob.signed_id )
                upload_field.val('')
                uploaded_file_text.html( blob.filename )

                setTimeout(() => {
                    this.$file_block.
                        removeClass('uploading').
                        addClass('uploaded')
                }, 300 * 4)
            }
        })
    }

    directUploadWillStoreFileWithXHR(request) {
        request.upload.addEventListener(
            "progress", 
            event => this.directUploadDidProgress(event)
        )
    }

    directUploadDidProgress(event) {
        // Use event.loaded and event.total to update the progress bar
        const { loaded, total } = event,
            percent = Math.round((loaded / total) * 100.0) + '%',
            progress_el = this.$file_block.find('.progress'),
            bar_el = progress_el.find('.progress--bar'),
            status_text_el = progress_el.find('.progress--status .text'),
            status_percent_el = progress_el.find('.progress--status .percent')

            bar_el.css('width', percent)
            status_text_el.html('UPLOADING')
            status_percent_el.html(percent)
    }

}