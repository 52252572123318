require("@rails/ujs").start()
require("@rails/activestorage").start()
require('cocoon-js')
require("channels")
require("../cms/modules")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
require.context('../../assets/images', true)  