import $ from 'jquery'

let current_action,
    current_element,
    youtube_player

function init() {
    setUpEventListeners()
}

function setUpEventListeners() {
    $('.modal .window .cancel-button').on('click', events.onModalWindowCancelButtonClick)
    $('.modal .window .confirm-button').on('click', events.onModalWindowConfirmButtonClick)
    $(document).on('click', '.trigger-modal', events.onTriggerModalClick);
}

function showModal(modal, title = undefined, message = undefined) {
    modal.addClass('active');
}

function hideModal(modal) {
    modal.removeClass('active')
}

function processCurrentAction() {
    switch (current_action) {
        case 'link-follow-through':
            (() => {
                const href = current_element.attr('href')

                window.location = href
            })()
            break;
        case 'stop-youtube-video':
            (() => {
                if (youtube_player && youtube_player.stopVideo) {
                    youtube_player.stopVideo()
                }
            })()
        case 'nothing':
            break;
        default:
            console.error('Bad modal action configured', current_action)
            break;
    }
}

function onYoutubeVideoAPIReady() {
    new YT.Player('how_to_use_youtube_video', {
        events: {
            onReady: events.onYoutubePlayerPlayerReady
        }
    })
}

const events = {
    onModalWindowCancelButtonClick: (e) => {
        const btn = $(e.currentTarget),
            modal = btn.closest('.modal')

        e.preventDefault()
        hideModal(modal)
    },
    onModalWindowConfirmButtonClick: (e) => {
        const btn = $(e.currentTarget),
            modal = btn.closest('.modal')

        e.preventDefault()
        processCurrentAction()
        hideModal(modal)
    },
    onTriggerModalClick: (e) => {
        const el = $(e.currentTarget),
            modal = $( el.data('modal') ),
            title = el.data('modal-title'),
            message = el.data('modal-message'),
            action = el.data('modal-action')

        e.preventDefault()

        if (modal.length) {
            modal.find('h2').html( title )
            modal.find('p').html( message )
            current_action = action
            current_element = el
            showModal(modal)
        }
    },
    onYoutubePlayerPlayerReady(e) {
        youtube_player = e.target
    }
}

export default { 
    init,
    onYoutubeVideoAPIReady
}