import $ from 'jquery'
import FroalaEditor from 'froala-editor'
import 'froala-editor/js/plugins/align.min'
///import 'froala-editor/js/plugins/char_counter.min'
///import 'froala-editor/js/plugins/code_beautifier.min'
///import 'froala-editor/js/plugins/code_view.min'
import 'froala-editor/js/plugins/colors.min'
///import 'froala-editor/js/plugins/emoticons.min'
import 'froala-editor/js/plugins/entities.min'
import 'froala-editor/js/plugins/file.min'
///import 'froala-editor/js/plugins/font_family.min'
import 'froala-editor/js/plugins/font_size.min'
///import 'froala-editor/js/plugins/fullscreen.min'
import 'froala-editor/js/plugins/help.min'
import 'froala-editor/js/plugins/image.min'
import 'froala-editor/js/plugins/image_manager.min'
import 'froala-editor/js/plugins/inline_class.min'
import 'froala-editor/js/plugins/inline_style.min'
import 'froala-editor/js/plugins/line_breaker.min'
import 'froala-editor/js/plugins/line_height.min'
import 'froala-editor/js/plugins/link.min'
import 'froala-editor/js/plugins/lists.min'
import 'froala-editor/js/plugins/paragraph_format.min'
import 'froala-editor/js/plugins/paragraph_style.min'
///import 'froala-editor/js/plugins/print.min'
///import 'froala-editor/js/plugins/quick_insert.min'
import 'froala-editor/js/plugins/quote.min'
///import 'froala-editor/js/plugins/save.min'
import 'froala-editor/js/plugins/table.min'
import 'froala-editor/js/plugins/special_characters.min'
import 'froala-editor/js/plugins/url.min'
//import 'froala-editor/js/plugins/video.min'
import 'froala-editor/js/third_party/embedly.min'
///import 'froala-editor/js/third_party/font_awesome.min'
///import 'froala-editor/js/third_party/spell_checker.min'

function init() {
    setUpFroala()
}

function setUpFroala() {
    $('textarea.froala').each((index, textarea) => {
        textarea = $(textarea)

        const id = textarea.attr('id'),
            placeholder = textarea.attr('placeholder')

        new FroalaEditor('#' + id, {
            key: 'oc1F2vF1G2E1B4B1E7mqcgB3B1dbwhmfyA-7seokhpG4uwmlnrF6nbh1eunA8B6E5B4B1D3F3A1B8A5==',
            attribution: false,
            placeholderText: placeholder,
            toolbarButtons: {
                moreText: {
                    buttons: [ 'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'textColor', 'clearFormatting' ],
                    buttonsVisible: 4
                },
                moreParagraph: {
                    buttons: [ 'paragraphFormat', 'paragraphStyle', 'formatOL', 'formatUL', 'alignLeft', 'alignCenter', 'alignRight', 'alignJustify', 'outdent', 'indent', 'quote'],
                    buttonsVisible: 4
                },
                moreRich: {
                    buttons: [ 'insertLink', 'insertImage' ]
                },
                moreMisc: {
                    buttons: [ 'undo', 'redo', 'fullscreen', 'help' ],
                    align: 'right',
                    buttonsVisible: 2
                }
            },
            paragraphFormat: {
                N: 'Normal',
                H2: 'Heading 2',
                H3: 'Heading 3'
            },
            paragraphStyles: {
                disclaimer: 'Disclaimer'
            },
            pastePlain: true
        })
    })
}

export default { init }