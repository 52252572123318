import $ from 'jquery'

function init() {
    setUpEventListeners()
}

function setUpEventListeners() {
    $('#container nav .nav-container ul li a').on('click', events.onNavAnchorClick)
    $('#container nav .nav-container #close_button').on('click', events.onNavCloseButtonClick)
    $('#container nav .nav-container #menu_button').on('click', events.onNavMenuButtonClick)
}

const events = {
    onNavAnchorClick: (e) => {
        const a = $(e.target),
            href = a.attr('href'),
            li = a.closest('li'),
            submenu = li.find('ul')

        if (submenu.length) {
            e.preventDefault()
            li.toggleClass('active')
        }
    },
    onNavCloseButtonClick: (e) => {
        e.preventDefault()
        $('#container').addClass('menu-closed')
    },
    onNavMenuButtonClick: (e) => {
        e.preventDefault()
        $('#container').removeClass('menu-closed') 
    }
}

export default { init }