import $ from 'jquery'

const LOAD_DELAY_TIME = 500

function init() {
    checkForFormActionBar()
    hideFormFlashMessages()
}

function checkForFormActionBar() {
    const bar = $('form #action_bar'),
        container = $('#container')

    if (bar.length) {
        container.addClass('action-bar-shown')
    }
}

function hideFormFlashMessages() {
    $('form .flash').each((index, flash) => {
        flash = $(flash)

        setTimeout(() => {
            flash.addClass('hide')
        }, 3000)
    });
}

export default { 
    init,
    LOAD_DELAY_TIME: LOAD_DELAY_TIME
}